import showdown from 'showdown';

import {getMcrGlossaryStaticLinkHtml} from './utils';

showdown.extension('mcr-glossary-static-link-old', function () {
  var mcrLink = {
    type: 'lang',
    // ![glossary-static][TEXT](Glossary popup text)
    // <a data-mcr-content="Glossary popup text" class="glossary-static">TEXT</a>

    regex: '!\\[glossary-static]\\[(.+)]\\((.+)\\)',
    replace: function (match, label, content) {
      return getMcrGlossaryStaticLinkHtml(label, content);
    },
  };
  return [mcrLink];
});
