import {escapeHtml} from '@common/utils/utils';
import fm from 'front-matter';

import MdHtmlConverter from './MdHtmlConverter';

const DESCRIPTIONS_INLINE = 'inline';

function getDataDefault(attributes) {
  const images = attributes.images || [];
  const tags = attributes.tags || [];
  return [images, tags];
}

function getDataDescriptionsInline(attributes) {
  let images = [];
  let tags = [];
  const attrImages = attributes.images || [];
  for (let i = 0; i < attrImages.length; i++) {
    const array = i % 2 === 0 ? images : tags;
    array.push(attrImages[i]);
  }
  return [images, tags];
}

function getExtension(currentSiteUsername) {
  var mcrGallery = {
    type: 'lang',
    filter: function (text) {
      var i = 0;
      var lines = text.split('\n');
      var line;
      var galleryContent = [];
      var out = [];
      for (i; i < lines.length; i += 1) {
        line = lines[i];

        // ::: mcr-gallery
        // title: Gallery Title
        // images: //Requires at least one ID in this or tag list
        //   - ID of Asset 1
        //   - ID of Asset 2
        // tags: //include all images with these tags
        //   - description of image 1
        //   - description of image 2
        // :::

        // or with descriptions: inline

        // ::: mcr-gallery
        // title: Gallery Title
        // descriptions: inline
        // images:
        //   - ID of Asset 1
        //   - description of image 1
        //   - ID of Asset 2
        //   - description of image 2
        // :::

        // =>

        // <div> <mcr-gallery title="xxx" images="xxxx" tags="tags"></mcr-gallery> </div>

        if (line.trim().match(/^::: mcr-gallery$/)) {
          line = line.trim();

          line = lines[++i];
          while (!line.trim().match(/^:::$/)) {
            line = line.trim();
            galleryContent.push(line);
            if (i + 1 >= lines.length) {
              break;
            }
            line = lines[++i];
          }
          try {
            let joinTag = '【mcr】';
            var content = fm('---\n' + galleryContent.join('\n') + '\n---');
            galleryContent = [];
            if (content.attributes) {
              const descriptionsType =
                content.attributes && content.attributes.descriptions ? content.attributes.descriptions.trim() : '';
              const method = descriptionsType === DESCRIPTIONS_INLINE ? getDataDescriptionsInline : getDataDefault;

              let [images, tags] = method(content.attributes);
              images = images ? images.join(joinTag) : '';
              tags = tags
                ? tags
                    .map(tag => {
                      return MdHtmlConverter.makeHtml(tag, currentSiteUsername, 'gallery-tag-');
                    })
                    .join(joinTag)
                : '';
              tags = escapeHtml(tags);
              const widths = content.attributes['widths'] ? content.attributes['widths'].join(joinTag) : '';
              const title = escapeHtml(content.attributes['title'] || '');

              out.push(
                `<div><mcr-gallery title="${title}" images="${images}" tags="${tags}" widths="${widths}"></mcr-gallery></div>\n`
              );
            }
          } catch (error) {
            // not json
            galleryContent = [];
            console.log(error);
          }
          continue;
        }
        out.push(line);
      }
      return out.join('\n');
    },
  };
  return [mcrGallery];
}

export default function (currentSiteUsername) {
  return () => getExtension(currentSiteUsername);
}
