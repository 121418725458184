import fm from 'front-matter';

import MdHtmlConverter from './MdHtmlConverter';

function getExtension(currentSiteUsername) {
  var mcrTitle = {
    type: 'lang',
    filter: function (text) {
      var i = 0;
      var lines = text.split('\n');
      var line;
      var titleContent = [];
      var out = [];
      for (i; i < lines.length; i += 1) {
        line = lines[i];

        // ::: mcr-header
        // title: xxxx
        // strapline: The Birth of Long Tau Wan //Optional
        // image: Optional, ID for image from MyTraces
        // :::

        // =>

        // <div>
        //   <mcr-header title="xxx" image="xxx" strapline="xxx"></mcr-header>
        // </div>

        // found  ::: mcr-title
        if (line.trim().match(/^::: mcr-header$/)) {
          line = line.trim();
          line = lines[++i];
          while (!line.trim().match(/^:::$/)) {
            line = line.trim();
            titleContent.push(line);
            if (i + 1 >= lines.length) {
              break;
            }
            line = lines[++i];
          }
          try {
            var content = fm('---\n' + titleContent.join('\n') + '\n---');
            titleContent = [];
            if (content.attributes) {
              let html = MdHtmlConverter.makeHtml(content.attributes['title'], currentSiteUsername, 'header-');
              const strapline = content.attributes['strapline'] || '';
              const title = html.replace(/</g, '{').replace(/>/g, '}').replace(/"/g, "'");
              const imagePosition = content.attributes['image-position'] || 'center';
              out.push(
                `<div><mcr-header title="${title}" image="${content.attributes['image']}" strapline="${strapline}" image-position="${imagePosition}"></mcr-header></div>\n`
              );
            }
          } catch (error) {
            // not json
            titleContent = [];
            console.log(error);
          }
          continue;
        }
        out.push(line);
      }
      return out.join('\n');
    },
  };
  return [mcrTitle];
}

export default function (currentSiteUsername) {
  return () => getExtension(currentSiteUsername);
}
