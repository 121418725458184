import MdHtmlConverter from '@common/utils/showdown/MdHtmlConverter';
import {handleExtension} from '@common/utils/showdown/utils';
import showdown from 'showdown';

showdown.extension('mcr-additional-services-footer', function () {
  // ::: additional-services-footer
  // Interested in more background stories & context, family tree connections, translation or communication services? [Get in touch!](https://www.mychinaroots.com/get-in-touch)
  // :::

  // <div class="additional-services-footer">
  // content
  // </div>

  function handler(content) {
    const contentText = content.join('\n');
    const converted = MdHtmlConverter.makeHtml(contentText);
    return `<div class="additional-services-footer">${converted}</div>`;
  }

  return [
    {
      type: 'lang',
      filter: function (text) {
        return handleExtension(/^::: additional-services-footer$/, text, handler);
      },
    },
  ];
});
