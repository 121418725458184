import McrCoverter from './mcrCoverter';

function getExtension(currentSiteUsername) {
  var mcrSiblings = {
    type: 'lang',
    filter: function (text) {
      var i = 0;
      var lines = text.split('\n');
      var line;
      var content = [];
      var out = [];
      for (i; i < lines.length; i += 1) {
        line = lines[i];

        // ::: mcr-siblings
        // - text
        // - text
        // :::

        // =>

        // <div> <mcr-siblings li="xxx"></mcr-siblings> </div>

        if (line.trim().match(/^::: mcr-siblings$/)) {
          line = line.trim();

          line = lines[++i];
          while (!line.trim().match(/^:::$/)) {
            content.push(line.trim());
            if (i + 1 >= lines.length) {
              break;
            }
            line = lines[++i];
          }
          try {
            let html = McrCoverter.makeHtml(content.join('\n'), currentSiteUsername);
            content = [];
            out.push(
              `<div><mcr-siblings li="${html
                .replace(/</g, '{')
                .replace(/>/g, '}')
                .replace(/"/g, "'")}"></mcr-siblings></div>`
            );
          } catch (error) {
            // not json
            content = [];
            console.log(error);
          }
          continue;
        }
        out.push(line);
      }
      return out.join('\n');
    },
  };
  return [mcrSiblings];
}

export default function (currentSiteUsername) {
  return () => getExtension(currentSiteUsername);
}
