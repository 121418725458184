import showdown from 'showdown';

showdown.extension('mcr-inline-commentary', function () {
  // ![C][whatever text]
  var mcrInlineCommentary = {
    type: 'lang',
    regex: '\\B(\\\\)?!\\[C\\]\\[+([\\S\\s]*?)\\]+\\B',
    replace: function (match, leadingSlash, text) {
      return `<span class="inline_commentary">${text}</span>`;
    },
  };
  return [mcrInlineCommentary];
});
