import showdown from 'showdown';

showdown.extension('mcr-inline-anchor-old', function () {
  var mcrInlineAnchor = {
    type: 'lang',
    // ![anchor](ID)
    // <a id="ID"></a>

    regex: '\\B(\\\\)?!\\[anchor\\]\\(+([\\S\\s]*?)\\)+\\B',
    replace: function (match, leadingSlash, iid) {
      return `<a id="${iid}"></a>`;
    },
  };
  return [mcrInlineAnchor];
});
