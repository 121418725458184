import showdown from 'showdown';

showdown.extension('mcr-leading-words-old', function () {
  // ::: mcr-leading-words
  // In July 2019, our researchers travelled to “Baisha” to investigate your Li ancestry.
  // :::

  // =>

  // <p><span class="leading_words">In July 2019, our researchers travelled to “Baisha” to investigate your Li ancestry.</span></p>

  const mcrLeadingWords = {
    type: 'lang',
    filter: function (text) {
      const lines = text.split('\n');
      let line;
      let out = [];
      let i = 0;

      for (i; i < lines.length; i++) {
        line = lines[i].trim();
        if (line.match(/^::: mcr-leading-words$/)) {
          let content = [];
          line = lines[++i].trim();
          while (!line.match(/^:::$/) && i < lines.length) {
            content.push(line);
            i = i + 1;
            line = i < lines.length ? lines[i].trim() : '';
          }
          out.push(`<mcr-leading-words>${content.join('\n')}</mcr-leading-words>`);
          continue;
        }
        out.push(line);
      }
      return out.join('\n');
    },
  };
  return [mcrLeadingWords];
});
