import fm from 'front-matter';
import showdown from 'showdown';

showdown.extension('mcr-wiki-sidebar', function () {
  var mcrExtension = {
    type: 'lang',
    filter: function (text) {
      var i = 0;
      var lines = text.split('\n');
      var line;
      var titleContent = [];
      var out = [];
      for (i; i < lines.length; i += 1) {
        line = lines[i];

        // ::: wiki-sidebar
        // source: wiki
        // id: THEIDFROMWIKI
        // :::

        // =>

        // <div class="sidebar">
        // content from wiki
        // </div>

        if (line.trim().match(/^::: wiki-sidebar$/)) {
          line = line.trim();
          line = lines[++i];
          while (!line.trim().match(/^:::$/)) {
            line = line.trim();
            titleContent.push(line);
            if (i + 1 >= lines.length) {
              break;
            }
            line = lines[++i];
          }
          try {
            var content = fm('---\n' + titleContent.join('\n') + '\n---');
            titleContent = [];
            if (content.attributes && content.attributes['id']) {
              out.push(
                `<div class="sidebar"><mcr-remote-wiki source="${content.attributes['source']}" id="${content.attributes['id']}"></mcr-remote-wiki></div>\n`
              );
            }
          } catch (error) {
            // not json
            titleContent = [];
            console.log(error);
          }
          continue;
        }
        out.push(line);
      }
      return out.join('\n');
    },
  };
  return [mcrExtension];
});
