import {getAsset} from '@common/utils/utils';
import showdown from 'showdown';

showdown.extension('mcr-image-src', function () {
  var mcrImageSrc = {
    type: 'lang',
    regex: 'src="(.*?)".*?',
    replace: function (match, leadingSlash, text, href, cclass) {
      if (match.indexOf('~@/') !== -1) {
        let arr = match.split('"');
        let imgSrc = `${arr[1].replace('~@/assets/', '')}`;
        return arr[0] + '"' + getAsset('/assets/' + imgSrc) + '"';
      }
      return match;
    },
  };
  return [mcrImageSrc];
});
