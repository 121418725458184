/* eslint-disable */
import showdown from 'showdown';

import showdwnAdditionalServicesFooter from './showdown-additional-services-footer';
import showdownAncestorID from './showdown-ancestor-ID';
import showdownClass from './showdown-class';
import showdownFacts from './showdown-facts';
import showdownFactsSidebar from './showdown-facts-sidebar';
import {
  getMcrAncestorExtension,
  getMcrCommentaryExtension,
  getMcrFeatureExtension,
  getMcrSidebarExtension,
} from './showdown-features';
import getMcrGalleryExtension from './showdown-gallery';
import showdownLink from './showdown-glossary';
import showdownGlossaryStaticLink from './showdown-glossary-static-link';
import showdownGlossaryStaticLinkShortcut from './showdown-glossary-static-link-shortcut';
import showdownHeader from './showdown-header';
import showdownImageSrcHandle from './showdown-image-src-handle';
import showdownInlineAncestor from './showdown-inline-ancestor';
import showdownInlineAnchor from './showdown-inline-anchor';
import showdownInlineCommentary from './showdown-inline-commentary';
import showdownLeadingWords from './showdown-leading-words';
import showdownPoems from './showdown-poems';
import showdownSiblings from './showdown-siblings';
import mcrSingleImageExtension from './showdown-single-image';
import showdownHalfColumnImages from './showdown-two-column-images';
import showdownWikiFeature from './showdown-wiki-feature';
import showdownWikiSidebar from './showdown-wiki-sidebar';
import showdownYoutube from './showdown-youtube';

function setupExtensions(currentSiteUsername) {
  return [
    showdownFacts(currentSiteUsername),
    showdownFactsSidebar(currentSiteUsername),
    showdownSiblings(currentSiteUsername),
    'mcr-two-column-images',
    mcrSingleImageExtension(currentSiteUsername),
    showdownHeader(currentSiteUsername),
    getMcrGalleryExtension(currentSiteUsername),
    getMcrFeatureExtension(currentSiteUsername),
    getMcrCommentaryExtension(currentSiteUsername),
    getMcrSidebarExtension(currentSiteUsername),
    getMcrAncestorExtension(currentSiteUsername),
    'mcr-wiki-sidebar',
    'mcr-wiki-feature',
    showdownClass(currentSiteUsername),
    showdownAncestorID(currentSiteUsername),
    'mcr-link',
    'mcr-glossary-static-link',
    'mcr-glossary-static-link-shortcut',
    'mcr-image-src',
    showdownInlineAncestor(currentSiteUsername),
    'mcr-inline-commentary',
    'mcr-inline-anchor',
    'mcr-leading-words',
    'mcr-youtube',
    'mcr-poems',
    'mcr-additional-services-footer',
  ];
}

export default class MdHtmlConverter {
  static makeHtml(md, currentSiteUsername, prefixHeaderId = '') {
    let converter = new showdown.Converter({
      extensions: setupExtensions(currentSiteUsername),
      simpleLineBreaks: true,
      prefixHeaderId: prefixHeaderId,
    });
    return converter.makeHtml(md ? md.trim() : md);
  }
}
