/* eslint-disable */
import showdown from 'showdown';

import showdownAncestorID from './showdown-ancestor-ID';
import showdownClass from './showdown-class';
import showdownFacts from './showdown-facts';
import getMcrGalleryExtension from './showdown-gallery';
import showdownGlossaryStaticLink from './showdown-glossary-static-link';
import showdownGlossaryStaticLinkShortcut from './showdown-glossary-static-link-shortcut';
import showdownHeader from './showdown-header';
import showdownImageSrcHandle from './showdown-image-src-handle';
import {
  getMcrAncestorExtension,
  getMcrCommentaryExtension,
  getMcrFeatureExtension,
  getMcrSidebarExtension,
} from './showdown-include-markdown';
import showdownInlineAncestor from './showdown-inline-ancestor';
import showdownInlineAnchor from './showdown-inline-anchor';
import showdownInlineCommentary from './showdown-inline-commentary';
import showdownLeadingWords from './showdown-leading-words';
import showdownLink from './showdown-link';
import showdownSiblings from './showdown-siblings';
import showdownSingleImage from './showdown-single-image';
import showdownHalfColumnImages from './showdown-two-column-images';
import showdownWikiFeature from './showdown-wiki-feature';
import showdownWikiSidebar from './showdown-wiki-sidebar';
import showdownYoutube from './showdown-youtube';

function setupExtensions(currentSiteUsername) {
  return [
    showdownFacts(currentSiteUsername),
    showdownSiblings(currentSiteUsername),
    'mcr-two-column-images-old',
    'mcr-single-image-old',
    showdownHeader(currentSiteUsername),
    getMcrGalleryExtension(currentSiteUsername),
    getMcrFeatureExtension(currentSiteUsername),
    getMcrCommentaryExtension(currentSiteUsername),
    getMcrSidebarExtension(currentSiteUsername),
    getMcrAncestorExtension(currentSiteUsername),
    'mcr-wiki-sidebar-old',
    'mcr-wiki-feature-old',
    showdownClass(currentSiteUsername),
    showdownAncestorID(currentSiteUsername),
    'mcr-link-old',
    'mcr-glossary-static-link-old',
    'mcr-glossary-static-link-shortcut-old',
    'mcr-image-src-old',
    showdownInlineAncestor(currentSiteUsername),
    'mcr-inline-commentary-old',
    'mcr-inline-anchor-old',
    'mcr-leading-words-old',
    'youtube-old',
  ];
}

class McrCoverter {
  static makeHtml(md, currentSiteUsername) {
    let converter = new showdown.Converter({
      extensions: setupExtensions(currentSiteUsername),
      simpleLineBreaks: true,
    });
    return converter.makeHtml(md);
  }
}
export default McrCoverter;
