import showdown from 'showdown';

showdown.extension('mcr-link-old', function () {
  var mcrLink = {
    type: 'lang',
    // ![glossary][TEXT](id)(class)
    // <a href="id" class="glossary class">TEXT</a>

    // regex:   '\\B(\\\\)?!\\[glossary\\]\\[+([\\S]+)\\]\\(+([\\S]+)\\)\\(+([\\S]+)\\)\\B',
    regex: '\\B(\\\\)?!\\[glossary\\]\\[+([\\S\\s]*?)\\]+\\(+([\\S\\s]*?)\\)+\\(+([\\S\\s]*?)\\)+\\B',
    replace: function (match, leadingSlash, text, href, cclass) {
      let mcrLink = false;
      if (cclass === 'wiki') {
        mcrLink = true;
      }
      return `<a mcrlink="${mcrLink}" href="${href}" class="glossary ${cclass}">${text}</a>`;
    },
  };
  return [mcrLink];
});
