import MdHtmlConverter from './MdHtmlConverter';

function getExtension(currentSiteUsername) {
  var mcrFacts = {
    type: 'lang',
    filter: function (text) {
      var i = 0;
      var lines = text.split('\n');
      var line;
      var content = [];
      var out = [];
      for (i; i < lines.length; i += 1) {
        line = lines[i];

        // ::: mcr-facts
        //   -  text
        //   - text
        // :::

        // =>

        // <div> <mcr-facts li="xxx"></mcr-facts> </div>

        if (line.trim().match(/^::: mcr-facts$/)) {
          line = line.trim();

          line = lines[++i];
          while (!line.trim().match(/^:::$/)) {
            content.push(line.trim());
            if (i + 1 >= lines.length) {
              break;
            }
            line = lines[++i];
          }
          try {
            let html = MdHtmlConverter.makeHtml(content.join('\n'), currentSiteUsername, 'facts-')
              .slice(4)
              .slice(0, -5);
            content = [];
            out.push(`<div><ul class="mcr-facts">${html}</ul></div>`);
          } catch (error) {
            // not json
            content = [];
            console.log(error);
          }
          continue;
        }
        out.push(line);
      }
      return out.join('\n');
    },
  };
  return [mcrFacts];
}

export default function (currentSiteUsername) {
  return () => getExtension(currentSiteUsername);
}
