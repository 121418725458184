import {escapeHtml} from '@common/utils/utils';

export function getMcrGlossaryStaticLinkHtml(label, content) {
  return `<mcr-static-glossary-link glossary-content="${escapeHtml(content)}" label="${escapeHtml(label)}" />`;
}

export function handleExtension(matchRegex, text, handler) {
  // replaces colon regex extension with html in text
  // e.g. text = `# Introduction
  // ::: mcr-video
  // :::`
  // handleExtension(/^::: mcr-video$/, text, () => <mcr-iframe-video/>)returns
  // `# Introduction
  // <video />

  let i = 0;
  let lines = text.split('\n');
  let line;
  let originalLine;
  let content = [];
  let out = [];
  for (i; i < lines.length; i += 1) {
    originalLine = lines[i];
    line = originalLine.trim();
    if (line.match(matchRegex)) {
      content = [];
      originalLine = lines[++i];
      line = originalLine.trim();
      while (i < lines.length - 1 && !line.match(/^:::$/)) {
        content.push(originalLine);
        originalLine = lines[++i];
        line = originalLine.trim();
      }
      try {
        const string = handler(content);
        out.push(string);
      } catch (error) {
        console.log(error);
      }
      continue;
    }

    out.push(originalLine);
  }

  return out.join('\n');
}
