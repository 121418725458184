import {handleExtension} from '@common/utils/showdown/utils';
import {escapeHtml} from '@common/utils/utils';
import fm from 'front-matter';
import showdown from 'showdown';

showdown.extension('mcr-poems', function () {
  // ::: mcr-poems
  // columns: 4
  // 1:
  //  - symbol
  //  - pinyin
  //  - meaning
  // :::

  // <mcr-poem-table>
  //  <mcr-poem />
  //  <mcr-poem />
  //  <mcr-poem />
  // </mcr-poem-table>

  const COLUMNS_KEY = 'columns';
  const DEFAULT_COLUMNS_NUMBER = 5;

  function handler(content) {
    const lines = [];
    const fmcontent = fm('---\n' + content.join('\n') + '\n---');
    const columnsNumber = fmcontent.attributes[COLUMNS_KEY] || DEFAULT_COLUMNS_NUMBER;
    let items = [];

    for (const [key, value] of Object.entries(fmcontent.attributes)) {
      if (key.toLowerCase() === COLUMNS_KEY) {
        continue;
      }
      const [symbol, pinyin, meaning] = value;
      items.push({number: key, symbol, pinyin, meaning});
    }
    items = escapeHtml(JSON.stringify(items));
    lines.push(`<mcr-poem-table columns-number="${columnsNumber}" items="${items}">`);
    lines.push('</mcr-poem-table>');
    return lines.join('\n');
  }

  return [
    {
      type: 'lang',
      filter: function (text) {
        return handleExtension(/^::: mcr-poems/, text, handler);
      },
    },
  ];
});
