import MdHtmlConverter from './MdHtmlConverter';

function getExtension(currentSiteUsername) {
  var mcrAncestorID = {
    type: 'lang',
    filter: function (text) {
      var i = 0;
      var lines = text.split('\n');
      var line;
      var out = [];
      var isOtherExtensionSign = false;
      for (i; i < lines.length; i += 1) {
        line = lines[i];

        // ::: ancestor-ID
        // :::

        // =>

        // <div class="ancestor" id="ID">
        // </div>

        if (line.trim().indexOf('::: ancestor-') !== -1) {
          let arr = line.trim().split(' ');
          let iid = arr[1].replace('ancestor-', '');
          var elem = 'div';

          out.push(`<${elem} class="ancestor" id="${iid}">`);

          line = line.trim();
          line = lines[++i];

          var inline = [];

          while (!line.trim().match(/^:::$/)) {
            line = line.trim();
            inline.push(line);
            if (line.indexOf('::: ') !== -1) {
              isOtherExtensionSign = true;
              line = lines[++i];
            }
            if (i + 1 >= lines.length) {
              break;
            }
            while (isOtherExtensionSign) {
              line = line.trim();
              if (line.match(/^:::$/)) {
                isOtherExtensionSign = false;
              }
              inline.push(line);
              if (i + 1 >= lines.length) {
                break;
              }
              line = lines[++i];
            }
            line = lines[++i];
          }
          // console.log("inline");
          // console.log(inline);
          let html = MdHtmlConverter.makeHtml(inline.join('\n'), currentSiteUsername, `ancestor-${iid}-`);
          // console.log("html");
          // console.log(html);
          out.push(html);
          out.push(`</${elem}>`);

          continue;
        }

        out.push(line);
      }
      return out.join('\n');
    },
  };
  return [mcrAncestorID];
}

export default function (currentSiteUsername) {
  return () => getExtension(currentSiteUsername);
}
