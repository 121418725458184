export default function (currentSiteUsername) {
  return () => {
    let mcrInlineAncestor = {
      type: 'lang',
      // ![ancestor][TEXT](id)
      // <a href="/report/judithleslietam/ancestors/#id" target="ancestors" class="ancestor">TEXT</a>

      regex: '\\B(\\\\)?!\\[ancestor\\]\\[+([\\S\\s]*?)\\]+\\(+([\\S\\s]*?)\\)+\\B',
      replace: function (match, leadingSlash, text, id) {
        return `<a href="/report/${currentSiteUsername}/ancestors/#${id}" target="ancestors" class="ancestor">${text}</a>`;
      },
    };
    return [mcrInlineAncestor];
  };
}
