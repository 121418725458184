import fm from 'front-matter';
import concat from 'lodash/concat';
import showdown from 'showdown';

showdown.extension('mcr-single-image-old', function () {
  var mcrSingleImage = {
    type: 'lang',
    filter: function (text) {
      var i = 0;
      var lines = text.split('\n');
      var line;
      var content = [];
      var out = [];
      for (i; i < lines.length; i += 1) {
        line = lines[i];

        // ::: image
        // image:  ID from myTraces
        // style: Example values: full, small
        //   - full
        // caption: This is a caption
        // :::

        // =>

        // <div><mcr-image imageStyle="xxx" image="xxx" caption="xxx"></mcr-image></div>

        if (line.trim().match(/^::: image$/)) {
          line = line.trim();

          line = lines[++i];
          while (!line.trim().match(/^:::$/)) {
            content.push(line);
            if (i + 1 >= lines.length) {
              break;
            }
            line = lines[++i];
          }
          try {
            var fmcontent = fm('---\n' + content.join('\n') + '\n---');
            content = [];
            if (fmcontent.attributes) {
              var imageClass = ['img'];
              if (fmcontent.attributes['style']) {
                imageClass = concat(imageClass, fmcontent.attributes['style']);
              }
              out.push(
                `<div><mcr-image imageStyle="${imageClass.join(' ')}" image="${
                  fmcontent.attributes['image']
                }" caption="${fmcontent.attributes['caption']}" imageWidth="${
                  fmcontent.attributes['width']
                }"></mcr-image></div>`
              );
            }
          } catch (error) {
            // not json
            content = [];
            console.log(error);
          }
          continue;
        }
        out.push(line);
      }
      return out.join('\n');
    },
  };
  return [mcrSingleImage];
});
