import MdHtmlConverter from './MdHtmlConverter';

function createIncludeMarkdownExtension(currentSiteUsername, tag) {
  var extension = {
    type: 'lang',
    filter: function (text) {
      var i = 0;
      var lines = text.split('\n');
      var line;
      var out = [];
      var isOtherExtensionSign = false;
      for (i; i < lines.length; i += 1) {
        line = lines[i];
        // ::: tag
        // :::

        // =>

        // <div class="tag">
        // </div>

        if (line.trim().match(new RegExp(`^::: ${tag}$`))) {
          out.push(`<div class="${tag}">`);

          line = line.trim();
          line = lines[++i];

          var inline = [];

          while (!line.trim().match(/^:::$/)) {
            line = line.trim();
            inline.push(line);
            if (line.indexOf('::: ') !== -1) {
              isOtherExtensionSign = true;
            }
            while (isOtherExtensionSign) {
              line = line.trim();
              if (line.match(/^:::$/)) {
                isOtherExtensionSign = false;
                inline.push(line);
              }
              if (i + 1 >= lines.length) {
                break;
              }
              line = lines[++i];
            }
            if (i + 1 >= lines.length) {
              break;
            }
            line = lines[++i];
          }

          let html = MdHtmlConverter.makeHtml(inline.join('\n'), currentSiteUsername, `${tag}-`);
          out.push(html);

          out.push(`</div>`);

          continue;
        }

        out.push(line);
      }

      return out.join('\n');
    },
  };
  return [extension];
}

export function getMcrCommentaryExtension(currentSiteUsername) {
  return () => createIncludeMarkdownExtension(currentSiteUsername, 'commentary');
}

export function getMcrFeatureExtension(currentSiteUsername) {
  return () => createIncludeMarkdownExtension(currentSiteUsername, 'feature');
}

export function getMcrSidebarExtension(currentSiteUsername) {
  return () => createIncludeMarkdownExtension(currentSiteUsername, 'sidebar');
}

export function getMcrAncestorExtension(currentSiteUsername) {
  return () => createIncludeMarkdownExtension(currentSiteUsername, 'ancestor');
}
