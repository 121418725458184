import fm from 'front-matter';
import showdown from 'showdown';

showdown.extension('mcr-two-column-images-old', function () {
  var mcrTwoColumnImages = {
    type: 'lang',
    filter: function (text) {
      var i = 0;
      var lines = text.split('\n');
      var line;
      var content = [];
      var out = [];
      for (i; i < lines.length; i += 1) {
        line = lines[i];

        // ::: half-column-images //requires two images
        // images:  ID from myTraces
        //  - //ID from myTraces
        //  - //ID from myTraces
        // :::

        // =>

        // <div><mcr-half-column-images images="xxx"></mcr-half-column-images></div>

        if (line.trim().match(/^::: half-column-images$/)) {
          line = line.trim();

          line = lines[++i];
          while (!line.trim().match(/^:::$/)) {
            content.push(line);
            if (i + 1 >= lines.length) {
              break;
            }
            line = lines[++i];
          }
          try {
            let joinTag = '【mcr】';
            var fmcontent = fm('---\n' + content.join('\n') + '\n---');
            content = [];
            if (fmcontent.attributes) {
              var images = [];
              var widths = [];
              if (fmcontent.attributes['images']) {
                images = fmcontent.attributes['images'].join(joinTag);
              }
              if (fmcontent.attributes['widths']) {
                widths = fmcontent.attributes['widths'].join(joinTag);
              }
              out.push(
                `<div><mcr-half-column-images imagesStr="${images}" widthsStr="${widths}"></mcr-half-column-images></div>`
              );
            }
          } catch (error) {
            // not json
            content = [];
            console.log(error);
          }
          continue;
        }
        out.push(line);
      }
      return out.join('\n');
    },
  };
  return [mcrTwoColumnImages];
});
