import {handleExtension} from '@common/utils/showdown/utils';
import fm from 'front-matter';
import showdown from 'showdown';

showdown.extension('mcr-youtube', function () {
  // ::: mcr-video
  // src: https://www.youtube.com/embed/code
  // previewImageSize: maxresdefault (optional, Other values: 'default', 'mqdefault', 'sddefault', 'hqdefault')
  // :::

  // <lazy-youtube-video src="https://www.youtube.com/embed/code" previewImageSize="hqdefault" />

  function handler(content) {
    const fmcontent = fm('---\n' + content.join('\n') + '\n---');
    const previewImageSize = fmcontent.attributes.previewImageSize || 'maxresdefault';
    const src = fmcontent.attributes.src;
    if (src.includes('youtube.com')) {
      return `<mcr-youtube-video src="${src}" previewImageSize="${previewImageSize}" />`;
    } else {
      return `<mcr-iframe-video src=${src} />`;
    }
  }

  return [
    {
      type: 'lang',
      filter: function (text) {
        return handleExtension(/^::: mcr-video$/, text, handler);
      },
    },
  ];
});
