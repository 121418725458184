import McrCoverter from './mcrCoverter';

function getExtension(currentSiteUsername) {
  var mcrClass = {
    type: 'lang',
    filter: function (text) {
      var i = 0;
      var lines = text.split('\n');
      var line;
      var out = [];
      var isOtherExtensionSign = false;
      for (i; i < lines.length; i += 1) {
        line = lines[i];

        // ::: class-classText
        // :::

        // =>

        // <div class="classText">
        // </div>

        if (line.trim().indexOf('::: class-') !== -1) {
          const classes = line.trim().replace('::: class-', '');

          var classElement = 'div';

          out.push(`<${classElement} class="${classes}">`);

          line = line.trim();
          line = lines[++i];

          var inline = [];

          while (!line.trim().match(/^:::$/)) {
            line = line.trim();
            inline.push(line);
            if (line.indexOf('::: ') !== -1) {
              isOtherExtensionSign = true;
              line = lines[++i];
            }
            if (i + 1 >= lines.length) {
              break;
            }
            while (isOtherExtensionSign) {
              line = line.trim();
              if (line.match(/^:::$/)) {
                isOtherExtensionSign = false;
              }
              inline.push(line);
              if (i + 1 >= lines.length) {
                break;
              }
              line = lines[++i];
            }
            line = lines[++i];
          }
          // console.log("inline");
          // console.log(inline);
          let html = McrCoverter.makeHtml(inline.join('\n'), currentSiteUsername);
          // console.log("html");
          // console.log(html);
          out.push(html);
          out.push(`</${classElement}>`);

          continue;
        }

        out.push(line);
      }
      return out.join('\n');
    },
  };
  return [mcrClass];
}

export default function (currentSiteUsername) {
  return () => getExtension(currentSiteUsername);
}
