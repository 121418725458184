import showdown from 'showdown';

import {getMcrGlossaryStaticLinkHtml} from './utils';

showdown.extension('mcr-glossary-static-link-shortcut-old', function () {
  var mcrLink = {
    type: 'lang',
    // [TEXT ~ Glossary popup text]
    // <a data-mcr-content="Glossary popup text" class="glossary-static">TEXT</a>

    regex: '\\[((?:(?!\\[|\\])[\\s\\S])*)\\]',
    replace: function (match, text) {
      if (text.includes(' ~ ')) {
        const [label, content] = text.split(' ~ ');
        return getMcrGlossaryStaticLinkHtml(label, content);
      }
      return match;
    },
  };
  return [mcrLink];
});
