import showdown from 'showdown';

showdown.extension('mcr-link', function () {
  var mcrLink = {
    type: 'lang',
    // ![glossary][TEXT](id)(class)
    // <a href="id" class="glossary class">TEXT</a>

    // regex:   '\\B(\\\\)?!\\[glossary\\]\\[+([\\S]+)\\]\\(+([\\S]+)\\)\\(+([\\S]+)\\)\\B',
    regex: '\\B(\\\\)?!\\[glossary\\]\\[+([\\S\\s]*?)\\]+\\(+([\\S\\s]*?)\\)+\\(+([\\S\\s]*?)\\)+\\B',
    replace: function (match, leadingSlash, text, href, cclass) {
      if (cclass && cclass.includes('wiki')) {
        return `<mcr-wiki-glossary-link natural-id="${href}" text="${text}" />`;
      }
      return `<mcr-local-glossary-link id="${href}" label="${text}" />`;
    },
  };
  return [mcrLink];
});
