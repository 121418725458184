import {escapeHtml} from '@common/utils/utils';
import fm from 'front-matter';

import McrCoverter from './mcrCoverter';

function getExtension(currentSiteUsername) {
  var mcrGallery = {
    type: 'lang',
    filter: function (text) {
      var i = 0;
      var lines = text.split('\n');
      var line;
      var galleryContent = [];
      var out = [];
      for (i; i < lines.length; i += 1) {
        line = lines[i];

        // ::: mcr-gallery
        // title: Gallery Title
        // images: //Requires at least one ID in this or tag list
        //   - //ID from myTraces
        //   - //ID from myTraces
        //   - //ID from myTraces
        //   - //ID from myTraces
        // tags: //include all images with these tags
        //   - //tag ID from myTraces
        //   - //tag ID from myTraces
        //   - //tag ID from myTraces
        // :::

        // =>

        // <div> <mcr-gallery title="xxx" images="xxxx" tags="tags"></mcr-gallery> </div>

        if (line.trim().match(/^::: mcr-gallery$/)) {
          line = line.trim();

          line = lines[++i];
          while (!line.trim().match(/^:::$/)) {
            line = line.trim();
            galleryContent.push(line);
            if (i + 1 >= lines.length) {
              break;
            }
            line = lines[++i];
          }
          try {
            let joinTag = '【mcr】';
            var content = fm('---\n' + galleryContent.join('\n') + '\n---');
            galleryContent = [];
            if (content.attributes) {
              const images = content.attributes['images'] ? content.attributes['images'].join(joinTag) : '';
              let tags = content.attributes['tags']
                ? content.attributes['tags']
                    .map(tag => {
                      return McrCoverter.makeHtml(tag, currentSiteUsername);
                    })
                    .join(joinTag)
                : '';
              tags = escapeHtml(tags);
              const widths = content.attributes['widths'] ? content.attributes['widths'].join(joinTag) : '';
              const title = escapeHtml(content.attributes['title'] || '');
              out.push(
                `<div><mcr-gallery title="${title}" images="${images}" tags="${tags}" widths="${widths}"></mcr-gallery></div>\n`
              );
            }
          } catch (error) {
            // not json
            galleryContent = [];
            console.log(error);
          }
          continue;
        }
        out.push(line);
      }
      return out.join('\n');
    },
  };
  return [mcrGallery];
}

export default function (currentSiteUsername) {
  return () => getExtension(currentSiteUsername);
}
